import { useState } from "react";
import { V3Button } from "../Button";
import { V3ButtonColor } from "../Button/V3Button.types";
import { V3IconBin, V3IconUpload } from "../Icons";
import { V3Loader } from "../Loader";
import { CCImgUploadInput, CCImgUploadWrapper, CustomMediaUploadingWrapper, MUWrapper } from "./MediaUploadWrapper.styles";
import { V3Tooltip } from "../Tooltip";
import { theme } from "../../theme/theme";

interface MediaUploadWrapperProps {
    onFileUploadHandler: (selectorFiles: FileList) => Promise<string>,
    onUploadSuccess: (filepath: string) => void,
    disabled?: boolean,
    onRemovePreview?: () => void,
}

export const MediaUploadWrapper = ({ onFileUploadHandler, onUploadSuccess, disabled = false, onRemovePreview }: MediaUploadWrapperProps) => {

    const [customImageUploading, setCustomImageUploading] = useState(false);

    return <MUWrapper>
        <div>
            <CCImgUploadWrapper disabled={disabled}>
                {
                    customImageUploading
                        ? <CustomMediaUploadingWrapper>
                            <V3Loader height={24} />
                        </CustomMediaUploadingWrapper>
                        : <>
                            <V3Button
                                text="Update preview image"
                                icon={<V3IconUpload />}
                                disabled={disabled}
                                color={V3ButtonColor.secondary}
                            />
                            <CCImgUploadInput
                                type="file"
                                disabled={disabled}
                                onChange={async (e) => {
                                    if (!e.target.files) return;
                                    setCustomImageUploading(true);

                                    try {
                                        const filepath = await onFileUploadHandler(e.target.files);
                                        setCustomImageUploading(false);
                                        onUploadSuccess(filepath);
                                    } catch {
                                        setCustomImageUploading(false);
                                    }
                                }}
                                accept=".png,.jpg"
                            />
                        </>
                }
            </CCImgUploadWrapper >
        </div>
        {
            !!onRemovePreview && <div onClick={onRemovePreview}>
                <V3Tooltip
                    referenceContent={<V3IconBin
                        stroke={disabled ? theme.v3.colors.grey48 : undefined}
                    />}
                    tooltipContent={"Delete preview image"}
                />
            </div>
        }
    </MUWrapper>
}