import styled, { css } from "styled-components";
import { media, theme } from "../../theme/theme";
import { V3CaptionGrey35 } from "../ReusableStyledComponents/V3CaptionGrey35";

const imageDim = css`
    padding-bottom: 53.71%;
    border-radius: 8px;
`;

export const MediaEditingWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    ${media.custom(theme.v3.layout.sidebarLayout.mobile)} {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    > div {
        &:first-child {
            margin-bottom: 20px;

            ${media.custom(theme.v3.layout.sidebarLayout.mobile)} {
                min-height: 350px;
                margin-bottom: 0;
            }
        }

        &:first-child,
        &:nth-child(2) {
            ${media.custom(theme.v3.layout.sidebarLayout.mobile)} {
                max-width: calc(50% - 30px);
                width: 100%;
            }
        }        
    }
`;

export const MPMediaCard = styled.div`
    border-radius: 12px;
    max-width: 380px;
    margin: 0 auto 0 0;

    ${media.custom(theme.v3.layout.sidebarLayout.mobile)} {
        margin: 0 auto;
        padding: 12px;
        background: #FFFFFF;
    }
`;

export const MPMediaImage = styled.div<{ image?: string }>`
    background: ${({ theme, image }) => {
        if (image) return `url(${image}) center center / cover no-repeat`;
        return theme.v3.colors.grey70;
    }};
    width: 100%;
    ${imageDim}
`;

export const PreviewLabelWrapper = styled.div`
    max-width: 380px;
  
    ${media.custom(theme.v3.layout.sidebarLayout.mobile)} {
        margin: 0 auto;
    }
`;

export const PreviewNoContentSet = styled.div`
    max-width:  200;
    text-align: center;
`;

export const PPPDFNote = styled.div`
    border: 1px solid ${({ theme }) => theme ? theme.v3.colors.grey88 : "black"};
    position: relative;
    ${imageDim}

    ${V3CaptionGrey35} {
        position: absolute;
        width: 100%;
        left: 50%;
        top: 50%;
        max-width: 300px;
        transform: translate(-50%, -50%);
    }
`;