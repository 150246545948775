import React from "react";
import { VerticalSpacing } from "../VerticalSpacing";
import { MediaEditingWrapper, MPMediaCard, MPMediaImage, PPPDFNote, PreviewLabelWrapper, PreviewNoContentSet } from "./V3PanelWithPreview.styles";
import { V3Body, V3HeadlineH4 } from "../Typography";
import { SidebarLayoutShowDesktop, SidebarLayoutShowMobile } from "../../layout/SideBarLayout";
import { V3InputLabel } from "../SharedInputComponent";
import { truncate } from "../../utils/article-text-utils";
import { ItemMetadata } from "../V3ProfileListItem";
import { ContentType } from "authory-api-types/dist/enums";
import { MAX_DESCRIPTION_SIZE } from "../../types/max_description_size";
import { V3BodyGrey35 } from "../ReusableStyledComponents/V3BodyGrey35";
import { InlineLink } from "../ReusableStyledComponents/InlineLink";
import { V3CaptionGrey35 } from "../ReusableStyledComponents/V3CaptionGrey35";

interface V3PanelWithPreviewProps {
    title?: string,
    description?: string,
    imageSrc?: string,
    showMediaPreview?: boolean,
    mainPanel: JSX.Element,
    showMetaContent?: boolean,
    date?: string,
    sourceName?: string,
    originalUrl?: string,
    contentType?: ContentType
    btnPanel?: JSX.Element,
    previewEmpty?: boolean,
    hidePreviewLabel?: boolean,
    isBulkImport?: boolean,
}

export const V3PanelWithPreview = ({
    title,
    description,
    imageSrc,
    showMediaPreview,
    mainPanel,
    showMetaContent = false,
    date,
    sourceName,
    originalUrl,
    contentType,
    btnPanel,
    hidePreviewLabel = false,
    isBulkImport
}: V3PanelWithPreviewProps) => {

    const isEmpty = !title?.length && !description?.length && !imageSrc?.length;
    const WrapperToUse = showMediaPreview ? MediaEditingWrapper : React.Fragment;
    const isPDF = contentType === ContentType.PdfFile;

    const showPreviewLabel = showMetaContent && !hidePreviewLabel && !isEmpty;

    return <WrapperToUse style={{ position: "relative" }}>
        <div>
            {mainPanel}
            <SidebarLayoutShowDesktop>
                {btnPanel}
            </SidebarLayoutShowDesktop>
        </div>
        {
            showMediaPreview && <div style={{ marginTop: isBulkImport ? -35 : 0 }}>
                {
                    isEmpty ? <PreviewNoContentSet>
                        <V3HeadlineH4>Enter title and description to see preview here.</V3HeadlineH4>
                    </PreviewNoContentSet> : <MPMediaCard>
                        <div>
                            <SidebarLayoutShowMobile>
                                <VerticalSpacing bottom={8}>
                                    <V3InputLabel><strong>Preview</strong></V3InputLabel>
                                </VerticalSpacing>
                            </SidebarLayoutShowMobile>
                            {
                                !!imageSrc && <VerticalSpacing bottom={24}>
                                    <MPMediaImage
                                        image={imageSrc}
                                    />
                                </VerticalSpacing>
                            }
                            {
                                isPDF && !imageSrc && <VerticalSpacing bottom={24}>
                                    <PPPDFNote>
                                        <V3CaptionGrey35>Note: If you don't upload an image, Authory will automatically generate a thumbnail of the PDF.</V3CaptionGrey35>
                                    </PPPDFNote>
                                </VerticalSpacing>
                            }
                            <VerticalSpacing bottom={8}>
                                <V3HeadlineH4>{title}</V3HeadlineH4>
                            </VerticalSpacing>
                            <V3Body>{truncate(description || "", MAX_DESCRIPTION_SIZE)}</V3Body>
                            {
                                showMetaContent && !!date && contentType && <VerticalSpacing top={12}>
                                    <ItemMetadata
                                        item={{
                                            originalUrl: originalUrl ? originalUrl : null,
                                            date,
                                            sourceName,
                                            type: contentType
                                        }}
                                        showRedirect={false}
                                    />
                                </VerticalSpacing>
                            }
                        </div>
                    </MPMediaCard>
                }
                {
                    showPreviewLabel && <VerticalSpacing top={28}>
                        <PreviewLabelWrapper>
                            <V3BodyGrey35>Preview of what the content will look like on your portfolio, in collections and when shared on social media. <InlineLink href="https://authory.com/help/en/articles/9667500-how-does-the-preview-of-content-items-work" target="_blank">Learn more</InlineLink></V3BodyGrey35>
                        </PreviewLabelWrapper>
                    </VerticalSpacing>
                }
            </div>
        }
        <SidebarLayoutShowMobile>
            {btnPanel}
        </SidebarLayoutShowMobile>
    </WrapperToUse>
}
